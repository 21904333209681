<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="OrderOverviewDrawer">
        <header class="OrderOverviewDrawer_Header flex justify-between items-center">
            <RouterLink
                :to="{ name: 'Account' }"
                class="p-2 rounded-full bg-day-on-primary dark:bg-night-on-surface"
            >
                <AppIcon
                    icon-name="user"
                    color="text-day-on-surface-medium-emphasis dark:text-night-on-surface-high-emphasis"
                />
            </RouterLink>

            <h1 class="flex-1 text-center">{{ $t('order.order-overview.my-order') }}</h1>

            <button
                @click="$emit('close')"
                class="p-2 rounded-full bg-day-on-primary dark:bg-night-on-surface"
            >
                <AppIcon
                    icon-name="exit"
                    color="text-day-on-surface-medium-emphasis dark:text-night-on-surface-high-emphasis"
                />
            </button>
        </header>

        <div class="mt-4 grid row-gap-4">
            <OrderCartItem
                v-for="cartItem in cart"
                :key="`cart-item-${cartItem.slot}`"
                :cart-item="cartItem"
            />
        </div>

        <div class="mt-4" :class="{ 'flex flex-col-reverse': appliedCoupon }">
            <div v-if="totalDeposit > 0" class="flex justify-between items-center">
                <span
                    class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('order.order-overview.deposit') }}</span>
                <span
                    class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ currency(totalDeposit) }}</span>
            </div>

            <div class="flex justify-between items-center">
                <span
                    class="text-base leading-6 font-medium">{{ $t('order.order-overview.total-price') }}</span>
                <span
                    class="text-base leading-6 font-medium">{{ currency(appliedCoupon ? totalPriceWithDiscount : totalPrice) }}</span>
            </div>
        </div>

        <div class="mt-4 grid row-gap-2">
            <!-- Payment Button -->
            <AppButton
                v-if="!isFreeVending"
                @click="togglePaymentMethodDrawer"
                type="primary"
                icon="arrow-right"
                icon-position="right"
                :disabled="!cart || cart.length === 0"
                :class="{ 'opacity-50': !cart || cart.length === 0 }"
            >
                {{ $t('order.order-overview.button-payment') }}
            </AppButton>

            <!-- Free Vending Button -->
            <AppButton
                v-if="isFreeVending"
                @click="submitOrder"
                type="primary"
                icon="arrow-right"
                icon-position="right"
                :disabled="!cart || cart.length === 0"
                :class="{ 'opacity-50': !cart || cart.length === 0 }"
            >
                {{ $t('order.order-overview.button-complete') }}
            </AppButton>

            <AppButton
                @click="$emit('close')"
                type="secondary"
            >
                {{ $t('order.order-overview.button-continue-shopping') }}
            </AppButton>
        </div>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import OrderCartItem from '@/components/Order/CartItem.vue';
// import OrderCouponCode from '@/components/Order/CouponCode.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import useOrder from '@/composables/useOrder';
import useMachine from '@/composables/useMachine';
import useAuth from '@/composables/useAuth';
import useFilters from '@/filters';

export default {
    name: 'OrderOverviewDrawer',

    components: {
        BaseDrawer,
        OrderCartItem,
        // OrderCouponCode,
        AppIcon,
        AppButton,
    },

    setup() {
        const { machine } = useMachine();

        const {
            cart,
            totalPrice,
            togglePaymentMethodDrawer,
            appliedCoupon,
            totalPriceWithDiscount,
            totalDeposit,
            isFreeVending,
            submitOrder
        } = useOrder();

        const { currency } = useFilters();
        const { loggedIn } = useAuth();

        return {
            cart,
            totalPrice,
            togglePaymentMethodDrawer,
            currency,
            appliedCoupon,
            totalPriceWithDiscount,
            loggedIn,
            totalDeposit,
            isFreeVending,
            submitOrder
        };
    },
};
</script>

<style lang="scss">
.OrderOverviewDrawer {
    .ui-BaseDrawer_Inner {
        background-color: theme("colors.day.01dp");
    }

    &_PriceValue::first-letter {
        color: theme("colors.night.01dp");
        font-weight: lighter;
    }
}

.mode-dark {
    .OrderOverviewDrawer {
        .ui-BaseDrawer_Inner {
            background-color: theme("colors.night.01dp");
        }

        &_PriceValue::first-letter {
            color: theme("colors.night-on-surface.medium-emphasis");
        }
    }
}
</style>
